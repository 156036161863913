import {
  createPlugin,
  createComponentExtension,
} from '@backstage/core-plugin-api';

export const sanofiDoraMetricsPlugin = createPlugin({
  id: 'sanofi-dora-metrics',
});

export const DoraMetricsCard = sanofiDoraMetricsPlugin.provide(
  createComponentExtension({
    name: 'DoraMetricsCard',
    component: {
      lazy: () => import('./components/Cards').then(m => m.DoraMetricsCard),
    },
  }),
);
