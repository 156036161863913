import React, { useState } from 'react';
import { BackstagePalette } from '@backstage/theme';
import {
  Chip,
  DialogContent,
  DialogTitle,
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  DoraApiResult,
  Summary,
} from '@internal/backstage-plugin-dora-metrics-backend';
import Dialog from '@material-ui/core/Dialog';
import {
  TableColumn,
  Table,
  CodeSnippet,
  Link,
} from '@backstage/core-components';
import { cleanQueryString } from '../../utils';

type CardProps = {
  palette: BackstagePalette;
  title: string;
  description: string;
  indicators: {
    invert?: boolean;
    elite: number;
    high: number;
    medium: number;
    low: number;
  };
  metrics: DoraApiResult['metrics'][keyof DoraApiResult['metrics']];
};

type StyleProps = {
  color: string;
};

const metricsStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    metricContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    metric: {
      color: ({ color }) => color,
      paddingRight: theme.spacing(1),
    },
    unit: {
      fontWeight: 'bolder',
    },
  }),
);

const MetricChips = ({
  metrics,
}: {
  metrics: DoraApiResult['metrics'][keyof DoraApiResult['metrics']];
}) => {
  const [open, setOpen] = useState(false);
  const [selectedMetric, setSelectedMetric] = useState<{
    key: string;
    summary: Summary;
  } | null>(null);

  const hasQuery = (metric: any): metric is { query: string } =>
    'query' in metric;

  const getTooltipTitle = (
    key: string,
    summary: Summary,
    metricData: DoraApiResult['metrics'][keyof DoraApiResult['metrics']],
  ): string => {
    const keysWithQuery = [
      'completedTickets',
      'totalResolvedBugs',
      'totalBugsIntroduced',
    ];
    return hasQuery(metricData) && keysWithQuery.includes(key)
      ? metricData.query
      : `${summary.value} ${summary.unit}`;
  };

  const handleChipClick = (key: string, summary: Summary) => {
    setSelectedMetric({
      key,
      summary,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedMetric(null);
  };

  interface DoraTableColumn extends TableColumn {
    dataSources: string[];
  }

  const fixedColumns: DoraTableColumn[] = [
    {
      dataSources: ['jira'],
      title: 'Ticket',
      field: 'ticketKey',
      render: (row: any) => (
        <Link to={row.jiraTicketLink} style={{ fontWeight: 'bold' }}>
          {row.jiraTicket}
        </Link>
      ),
    },
    {
      dataSources: ['jira'],
      title: 'Flag Name',
      field: 'flagName',
      render: (row: any) => {
        if (row?.flags?.length > 0) {
          return row.flags.map((flagObj: any) => {
            const flagName = flagObj.name;
            const flagUrl = `https://app.launchdarkly.com/${flagObj.environments?.production?._site?.href}`;

            return flagUrl ? (
              <Link
                key={flagObj.creationDate}
                to={flagUrl}
                target="_blank"
                style={{ marginRight: '8px' }}
              >
                {flagName}
              </Link>
            ) : (
              <span key={flagObj.creationDate} style={{ marginRight: '8px' }}>
                {flagName}
              </span>
            );
          });
        }
        return 'N/A';
      },
    },
    {
      dataSources: ['jira'],
      title: 'First Started',
      field: 'firstStarted',
      render: (row: any) =>
        row.startDate ? new Date(row.startDate).toLocaleString() : 'N/A',
      customSort: (row1: any, row2: any) => {
        return (
          new Date(row1.startDate).getTime() -
          new Date(row2.startDate).getTime()
        );
      },
    },
    {
      dataSources: ['jira'],
      title: 'Resolved',
      field: 'resolved',
      render: (row: any) =>
        row.resolutionDate
          ? new Date(row.resolutionDate).toLocaleString()
          : 'N/A',
      customSort: (row1: any, row2: any) => {
        return (
          new Date(row1.resolutionDate).getTime() -
          new Date(row2.resolutionDate).getTime()
        );
      },
    },
    {
      dataSources: ['jira'],
      title: 'Time to Complete',
      field: 'timeToComplete',
      render: (row: any) =>
        row.resolutionTime ? `${row.resolutionTime} days` : 'N/A',
      customSort: (row1: any, row2: any) => {
        return (row1.resolutionTime || 0) - (row2.resolutionTime || 0);
      },
    },
    {
      dataSources: ['jira'],
      title: 'Time to Release Feature Flag',
      field: 'timeToReleaseFlaggedFeature',
      render: (row: any) =>
        row.timeToReleaseFlaggedFeature
          ? `${row.timeToReleaseFlaggedFeature} days`
          : 'N/A',
      customSort: (row1: any, row2: any) => {
        return (
          (row1.timeToReleaseFlaggedFeature || 0) -
          (row2.timeToReleaseFlaggedFeature || 0)
        );
      },
    },
    {
      dataSources: ['datadog'],
      title: 'Repository',
      field: 'repository',
      highlight: true,
      width: '30%',
    },
    {
      dataSources: ['datadog'],
      title: 'Pipeline name',
      field: 'pipelineName',
      render: (row: any) => (
        <Link to={row.url} target="_blank">
          <Typography variant="body2">{row.pipelineName}</Typography>
        </Link>
      ),
      width: '20%',
    },
    {
      dataSources: ['datadog'],
      title: 'Release date',
      field: 'releaseDate',
      render: (row: any) => new Date(row.releaseDate).toLocaleDateString(),
      width: '10%',
    },
    {
      dataSources: ['datadog'],
      title: 'Status',
      field: 'status',
      width: '10%',
    },
    {
      dataSources: ['datadog'],
      title: 'Author',
      field: 'author',
      width: '20%',
    },
  ];

  const filteredHeader = (datasource: string, unit?: string) =>
    fixedColumns.filter(column => {
      if (
        unit?.includes('bugs') &&
        column.field === 'timeToReleaseFlaggedFeature'
      ) {
        return false;
      }
      return column.dataSources.includes(datasource);
    });

  const generatedTitle = () => {
    if (
      selectedMetric?.summary.source === 'jira' &&
      selectedMetric?.summary.query
    ) {
      return (
        <>
          <Typography variant="h6">
            JQL query for {selectedMetric?.summary.unit}
          </Typography>
          <Typography variant="body2">
            <CodeSnippet
              showCopyCodeButton
              text={cleanQueryString(selectedMetric.summary.query)}
              language="jql"
            />
          </Typography>
        </>
      );
    } else if (selectedMetric?.summary.source === 'datadog') {
      return (
        <>
          <Typography variant="h6">
            Datadog pipeline query results for {selectedMetric?.summary.unit}
          </Typography>
          {selectedMetric?.summary.query && (
            <Typography variant="body2">
              <CodeSnippet
                showCopyCodeButton
                text={cleanQueryString(selectedMetric.summary.query)}
                language="jql"
              />
            </Typography>
          )}
        </>
      );
    }
    return <></>;
  };

  return (
    <>
      <Grid container spacing={1} justifyContent="center">
        {Object.entries(metrics.summary).map(([key, summary]) => (
          <Grid item key={key}>
            <Tooltip title={getTooltipTitle(key, summary, metrics)}>
              <Chip
                label={`${summary.value} ${summary.unit}`}
                variant="outlined"
                color="default"
                size="medium"
                onClick={() => handleChipClick(key, summary)}
              />
            </Tooltip>
          </Grid>
        ))}
      </Grid>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        {selectedMetric?.summary.source && (
          <>
            <DialogTitle>Dora Metrics Details</DialogTitle>
            <DialogContent>
              <Table
                title={generatedTitle()}
                options={{ search: false, paging: false }}
                columns={filteredHeader(
                  selectedMetric.summary.source,
                  selectedMetric.summary.unit,
                )}
                data={selectedMetric.summary.data ?? []}
              />
            </DialogContent>
          </>
        )}
      </Dialog>
    </>
  );
};

export const DoraMetricCard = (props: CardProps) => {
  const { palette, title, description, indicators, metrics } = props;

  const { value, unit } = metrics.result;

  const numVal = Number(metrics?.result.value);
  let color = null;
  if (indicators.invert) {
    if (numVal >= indicators.elite) {
      color = palette.status.ok;
    } else if (numVal >= indicators.high) {
      color = palette.status.running;
    } else if (numVal >= indicators.medium) {
      color = palette.status.warning;
    } else if (numVal >= indicators.low) {
      color = palette.status.error;
    } else {
      color = palette.status.error;
    }
  } else {
    if (numVal <= indicators.elite) {
      color = palette.status.ok;
    } else if (numVal <= indicators.high) {
      color = palette.status.running;
    } else if (numVal <= indicators.medium) {
      color = palette.status.warning;
    } else {
      color = palette.status.error;
    }
  }

  const classes = metricsStyles({ color });

  return (
    <Grid
      container
      item
      justifyContent="center"
      alignItems="center"
      direction="column"
      spacing={2}
      xs={12}
    >
      <Typography variant="h6">{title}</Typography>
      <Typography variant="body2">{description}</Typography>
      <Grid container item alignItems="center" justifyContent="center">
        <Typography className={classes.metric} variant="h2">
          {value?.toString()}
        </Typography>
        <Typography className={classes.unit}>{unit}</Typography>
      </Grid>
      <MetricChips metrics={metrics} />
    </Grid>
  );
};
